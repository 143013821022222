import React from "react"
import Layout from "../components/layout-old"


export default () => (

    <Layout>
            <img src="../assets/rizbizkits-logo.png" />

        <h5>This is my contact page</h5>
    </Layout>

)

